import { faCheck,faFolder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import '../home.css';

export default function Home() {
    return (
        <div className='home__contents'>
            <div className='app_top_banner'>
            App Icon <br />
            Sudokyu World - Largest Sudoku Game <br/>
            planet-do-team <br/>
            GET - On the App Store <br/>
            </div>
            <div className='home_app_simple'>
                    <AnimationOnScroll initiallyVisible={true}>
                        <div className='app_info_title'>
                            <h2><b><span>Sudokyu World</span></b></h2>
                            <h2> The App That Makes You Focus On!</h2>
                        </div>
                        <div className='app__download'>
                            <a href="https://play.google.com/store/apps/details?id=com.hjkyu.sudokyu.flutter_sudokyu" target="_blank" rel="noreferrer"><img src={require('../assets/web_icon_appdown_android.png')} alt="androidimg" /></a>
                            <a href="https://apps.apple.com/app/sudokyu-world/id6445858357" target="_blank" rel="noreferrer"><img src={require('../assets/web_icon_appdown_ios.png')} alt="appstoreimg" /></a>
                        </div>
                    </AnimationOnScroll>

                    <AnimationOnScroll initiallyVisible={true}>
                        <div className='app_screenshot'>
                            <img src={require('../assets/sudokyuScreenShot.png')} alt="screenshots"/>
                        </div>
                    </AnimationOnScroll>

                    <AnimationOnScroll animateIn="animate__fadeIn">
                        <div className='app_info_card'>
                            <FontAwesomeIcon icon={faCheck}/>
                            <h4>Check Out Your Level</h4>
                            <h5>free to choose the difficulty level.</h5>
                        </div>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateIn="animate__fadeIn">
                        <div className='app_info_card'>
                            <FontAwesomeIcon icon={faFolder}/>
                            <h4>AutoSave Supported</h4>
                            <h5>The game can be resumed at any time.</h5>
                        </div>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateIn="animate__fadeIn">
                        <div className='app_info_card'>
                            <FontAwesomeIcon icon={faCheck}/>
                            <h4>Hint and Check supported</h4>
                            <h5>Provide game hints and confirmations.</h5>
                        </div>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateIn="animate__fadeIn">
                         <div className='app_info_card'>
                             <FontAwesomeIcon icon={faCheck}/>
                             <h4>Hint and Check supported</h4>
                             <h5>Provide game hints and confirmations.</h5>
                         </div>
                    </AnimationOnScroll>
            </div>

            <div className='home_app_tail'>
                    <AnimationOnScroll  animateOnce={true} animateIn="animate__fadeIn">
                                  <div className='app_info_detail'>
                                          <h2>
                                            What is the max level you can reach?<br />
                                            Check This out!
                                          </h2>
                                          <h4>
                                          Sudoku World is a very large sudoku game app.<br/>
                                          (Why Sudokyu ? The designer’s name contains 'kyu')<br/>
                                          Do you like problem solving and want something to focus on?<br/>
                                          More than 1,000 SUDOKU puzzles of varying difficulty are waiting for you.<br/>
                                          </h4>
                                  </div>
                    </AnimationOnScroll>
            </div>
        </div>
    );
}