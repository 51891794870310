import { Link } from 'react-router-dom';
import logo from '../assets/logo.svg';
import '../style.css';
import React, { useState } from "react";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Header() {

const [toggleOn, setToggleOn] = useState(false);

const handleToggle = () => {
    console.log("toggleOn",toggleOn)
    setToggleOn(prev => !prev)
}

    return (
        <div className='header'>
            <nav class='navbar'>
                <div className='navbar__logo'>
                    <a href="/"><img src={logo} alt='logo' /></a>
                    <a href="/"><b>PLANET-DO</b></a>
                </div>
                <div className='navbar__menu' style={{display: toggleOn ? 'none' : 'flex'}}>
                    <li><Link className='navlink' to="/">App Download</Link></li>
                    <li><Link className='navlink' to="/policy">Private Policy</Link></li>
                    <li><Link className='navlink' to="/contact">Contact Us</Link></li>
                    <li><Link to="/userDelete">Delete Account</Link></li>
                </div>
                <a href="#!" class="navbar__toggleBtn">
                <FontAwesomeIcon icon={faBars} onClick={() => handleToggle()}  value={toggleOn} name="toggle" />
                </a>
            </nav>
        </div>
    );
}