import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './component/Header';
import Policy from './component/Policy';
import Contact from './component/Contact';
import Home from './component/Home';
import Tail from './component/Tail';
import './style.css';
import CookieConsent from "react-cookie-consent";
import UserDelete from "./component/user-delete";

function App() {
  return (
    <BrowserRouter>
    <div className='body'>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/sudokyu" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/userDelete" element={<UserDelete />} />
      </Routes>
      <Tail />
    </div>
    <CookieConsent>We use cookies and similar technologies to enable services and functionality on our site and to understand your interaction with our service. By clicking on accept, you agree to our use of such technologies for marketing and analytics.See <a href="/policy"><u>Privacy Policy</u></a></CookieConsent>
    </BrowserRouter>
  );
}

export default App;
