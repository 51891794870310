import '../style.css';

export default function UserDelete() {
    return (
        <div className='contact__contents'>
            <p>Users can enjoy using the <b>Planet-Do-Team.</b> apps without creating an account.<br />
                For those who have signed up and wish to delete their personal information,
                you can easily do so by following these steps:</p>

            <p>1. Access the "Sudoku War" app.</p>
            <p>2. Navigate to <b>Settings</b> > <b>User</b> </p>
            <p>3. Click on <b>"Delete Account."</b></p>

            <p> By following these steps, you can securely delete your account,
                including associated <b>UserId and Email </b> information.</p>

        </div>
    );
}