import '../style.css';

export default function Policy() {
    return (
        <div className='policy__contents'>
            {/* <h1>ddddd {day}</h1> */}
<p>Issue Date: 26/02/2023</p>

<h1>PRIVACY POLICY</h1>
<p>
Please read this Privacy Policy carefully to understand our policies and practices regarding your Personal Data (as defined below) and how we will treat it.
This Privacy Policy applies to planet.do.team’s games, apps, websites and related services (together, the “Services” or “games”, “apps”, “websites” for individual categories). This Privacy Policy explains how we, and affiliated companies
(collectively, "we", "us", "our") collect, use, secure, and/or disclose end-users’ (“you” or “your”) or otherwise processes your personal information when you use our Services.
</p>

<h2>1. INFORMATION WE COLLECT AND HOW</h2>

<p>
While using our Services, we may ask you to provide us with certain personal data or personal information that can be used to identify you (“Personal Data”, or “data” or “information” or “Personal Information”). We may also collect Personal Data automatically, or from third-party partners or services. The Personal Data we collect includes:
<h3>Basic Identifiers and Contact Information</h3>
We collect some information from you when you provide it to us directly, such as via an email or online form, through the support feature embedded in our Services, or through another form of inquiry. This information may include your name, email, and phone number. If you apply for a job on our website, information you submit may also include a cover letter and any details included in your resume or curriculum vitae (CV).
<br/>
<h3>Device Information</h3>
When you download and use our Services, we automatically collect information on the type of device you use, operating system, resolution, application version, mobile device identifiers (such as your device ID or advertising ID), language, time zone and IP address.
<h3>Usage Information</h3>
We collect information automatically about your activity through our Services (Usage Information), such as the date and time you used a service, features you have used, your in-app purchase history, subscriptions, your interaction with advertisements, and data generated when you use our Services (for example your game progress which may be stored with your Apple ID on iCloud for iOS Devices, with your Google Play Games profile for Android Devices and with Facebook, in some of our Services).
<h3>Location</h3>
When you download and use our Services, we automatically detect your general location, namely country and state through your IP address. We may collect, with your consent if applicable, other location information through your IP address, such as the city you are in.
<h3>Media and other files</h3>
We may ask for access to your camera in case you want to use certain features of our Services. In case you contact us through our in-app support feature and you need to upload any media or files as attachments, we shall ask for access to such files.
<h3>Information we obtain from third parties</h3>
We may receive information about you from our third party service providers, who collect this information through our Services in accordance with their own privacy policies. A list of the third parties that operate in our Services can be found in the APPENDIX B below.
Information we obtain when you connect with third parties
In cases we provide the option, and you decide, to connect our Services to your account with third parties like Apple, Facebook or Google, we may receive certain publicly available information about you, as well as any information that you allow or your settings enable to be accessed, including your Apple ID, Facebook user ID, Android Gamer Name, name and email subject to your permission. If you would like to manage your use of “Sign in with Apple” please go to Apple’s website to find out more. If you would like to edit the privacy and settings on Facebook, please go to Facebook’s website to find out more. If you would like to manage your use of “Sign in with Google Play Games” please go to Google’s website to find out more.
<h3>Information we obtain from Facebook</h3>
If you use our Services on Facebook or connect our Services to a Facebook account, we may receive any of the information you provide or permit to be provided to us via Facebook. Such information may include your user ID and your use of our game, including with whom you share our game on Facebook and your email address in case you opt to provide the same or wish to contact us for support. The information we receive depends on the Services you are using, Facebook's terms of service and privacy policy, your privacy settings, and, if applicable, your friends’ privacy settings. If you are uncertain about what information Facebook is sharing with us, please go to Facebook’s website to find out more about Facebook’s privacy practices.
The information we receive from Facebook and other third parties is stored and used by us in accordance with this Privacy Policy.
<h3>User generated content we obtain from you</h3>
We may collect content from posts or uploads on social networks or platforms or direct contact, subject to your consent if applicable. Such content may include, your name, social media username, image, likeness, voice, other identifiable information available in such user generated content.
<h3>Cookies and similar data collection technologies</h3>
We and our third party advertising networks and their partners may use cookies and similar technologies, such as mobile “SDK”, to provide and personalize our Services and to provide targeted advertisements. Some of these technologies can synchronize behavior across different mobile apps, devices and websites so that advertisements can be tailored to your interests. This process is called personalized advertising. In addition, we and certain advertising networks may access your inventory of installed apps to avoid advertising already installed apps. If you'd like to opt-out from having advertisements personalized in this way on your mobile device, please see the ‘Opt-out of targeted advertising’ section below.
Please note that, while your browser settings may allow you to transmit a “Do Not Track” signal when you visit the Services, our Services (including apps and websites) are not configured to respond to “Do Not Track” signals received from browsers.
For more information on how third parties may use tracking technologies in our apps, go to APPENDIX A and APPENDIX B further below.
</p>
<h2>
2. PURPOSES FOR WHICH WE WILL USE YOUR PERSONAL DATA
</h2>
<p>
We have set out below a description of the ways in which we use your Personal Data, the legal bases we rely upon to do so and to provide you with an option to opt-out of certain practices, where applicable. We may use your Personal Data:
To provide and maintain our Services, recognize you when you return to our apps, to sync your progress across devices when you log in to your account with third parties and perform essential business operation, our legal basis for processing is performance of a contract with you which you entered into with us when you download our Services and accept our End User License Agreement.
To allow you to back-up and sync your progress using third party social networks to our Services, such as Google Play Games, Apple Game Center or Facebook. Some of our Services provide the option to sync and back-up your progress across different devices when you log-in through third party social networks. These services are not activated automatically, but require explicit authorization by you. Please note that the third party social networks mentioned in this section are governed by their own privacy policies, terms and end-user agreements and we are not responsible for and have no control over the sharing practices or policies of the third parties that operate those sites. Further, when you disable the back-up feature, certain information might remain on your device(s) or the third party social network. To delete these remaining data, please visit your device’s or third party social network settings.
To administer and protect our Services (including troubleshooting, data analysis, testing, support, fraud, reporting and hosting of data). Our legal basis for processing is legitimate interests for running our business, provision of administration and services.
To communicate with you about any career requests, contact forms, helpdesk support and to understand how you use our Services so that we can continuously improve our offerings. Our legal basis is performance of a contract, legitimate interests or consent depending on the particular case to keep our users updated about our Services and our new features in accordance with your preference, and to continue to improve the Services we offer.
To opt-out of communication with us for our Services, send us your request through the online contact us support feature.
To cross-advertise our Services and products and to send push notifications for changes to our Services, to allow you to participate in new features of our Services when you choose to do so. Our legal basis is legitimate interests to be able to advertise our Services and products to our users and keep our users updated about our Services and our new features in accordance with your preference.
To opt-out of receiving advertisements for our Services and products, send us a request through the in-app support feature, by going to our app -> click on three dots (or more) -> click help (or support) -> and then click Contact us (or +) and send us your request or by going to the app Setting -> About -> and then Privacy Settings.
You can opt-out of receiving push notifications by turning them off at the device level through the settings of the device.
To serve personalized advertisements in our Services using third-party advertising networks. Our legal basis is legitimate interests to run our business. However, if you are in the European Economic Area, the United Kingdom (the "UK"), Norway, Switzerland or Brazil, some of our third-party advertising networks, will rely on your consent to process Personal Data in order to provide personalized advertising to you, therefore you will be asked whether you consent to the collection of your mobile device advertising identifier and IP address so that they can provide a personalized advertisement experience.
To opt-out or withdraw your consent from receiving personalized advertisements, see the “Opt-out of targeted advertising” section below.
To use user generated content which has been provided by you and such content includes Personal Data. Our legal basis is consent which is obtained directly by you from the medium (e.g. e-mail, social networks, social platforms) used to obtain such content.
You may withdraw your consent by providing us with written notice by email at planet.do.team@gmail.com
To respond to legal proceedings and other obligations or requirements under applicable law.
</p>
<h2>
3. RETENTION OF PERSONAL DATA
</h2>
<p>
We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, provide our Services and enforce our legal agreements and policies.
We will also retain Personal Data and Usage Information for internal analysis purposes and legitimate business interest to the extent necessary to improve the functionality of our Services, for business purposes or when we are legally obligated to retain this data for longer time periods.
Your Personal Data collected through our Services will be retained for as long as permitted by applicable law or necessary depending on the respective purpose of collection and shall be deleted when they no longer serve one of the above-mentioned purposes or shall be anonymized and therefore will no longer constitute Personal Data. It is clarified that in no case the retention period should exceed the 5 years from the date of collection. Once we have deleted or anonymized your Personal Data, you will not be able to exercise the right to access, the right to erasure, the right to rectification or the right to data portability.
</p>
<h2>
4. YOUR RIGHTS AND OPTIONS
</h2>
<p>
Access Personal Data we hold about you
You have the right to request access to your Personal Data collected through our Services. Following your request, we will provide you a copy of your Personal Data in an electronic format.
For Personal Data you send us through our Get in Touch form on our website, simply send us your data access request through the same form as a Customer Support ticket.
Other rights
You also have other rights such as to correct your data, have your data deleted, object how we use or share your data, and restrict how we use or share your data. You also have the right to withdraw consent where you have previously given your consent to the processing of your Personal Data, for example by turning off camera access in your mobile device settings. This will not affect any data processed before such withdrawal.
<h3>Opt-out of targeted advertising</h3>
To show you personalized advertisements in our apps we use third-party advertising networks and their partners, including those that need your consent and listed in ‘APPENDIX A - Advertising networks and their partners’ below, to deliver advertisements that are tailored to you based on a determination of your characteristics or interests. To do so they use personal and non-personal information such as advertising identifiers i.e. the Android advertising ID and/or Apple's ID for advertising (IDFA), device data and/or other tracking technologies to enable and optimize this advertising procedure.
You can opt-out from personalized advertisement experience, at any time by checking the Privacy Setting tab in any of our apps or under the privacy settings of your iOS or Android device.
Method 1 (depending on your local privacy laws) - App Settings:
</p>

<p>Open app Settings</p>
<p>
Select About/About Game
<h3>Select Privacy Setting</h3>
Select Advertising Preferences (or “Do Not Sell or Share my Personal Information”)
Switch off the Enable All toggle, and then click Confirm Choices (Android) or Save & Exit (iOS)
<h3>Method 2 - Device Settings:</h3>
<h3>For iOS 14 and later:</h3>
<h3>Open device Settings</h3>
<h3>Select Privacy (& Security)</h3>
Select Tracking and disable "Allow Apps to Request to Track" or disable permissions for specific app
<h3>For iOS 13 and older:</h3>
Open device Settings
<h3>Select Privacy</h3>
Select Advertising and enable "Limit Ad Tracking"
<h3>For Android:</h3>
Open device Settings
<h3>Select Google</h3>
Select Ads and enable "Opt out of Ads Personalization" or "Delete advertising ID" accordingly.
When you choose to opt-out, advertising networks will consider this choice as a withdrawal of consent to personalized advertisement experience and they will show contextual advertisements and not personalized advertisements based on your interests.
Note: Irrespective of your choice and/or your device settings, you will continue to see non-customized advertising (e.g. “contextual” advertising) and you may continue to receive personalized ads from our third-party ad networks that obtain your consent on other apps and/or websites. For more information go to ‘APPENDIX A - Advertising networks and their partners’ below.
<h3>Exercise your privacy rights</h3>
Any requests to exercise your rights should be made through Privacy Setting tab in any of our apps located in Setting -> About -> and then Privacy Settings or in our support feature located in our apps (by going to the particular app -> click on three dots (or more) -> click help (or support) -> and then click Contact us (or +) and place your request). Any requests regarding our game(s) available on Facebook should be made through the embedded support feature by going to the game -> click on the settings icon -> click help and place your request.
You can also send us an email at planet.do.team@gmail.com, however, we may still redirect you to make the same request through the in-app support feature or we may request additional information in order to accommodate your request.
These requests can be exercised free of charge and will be addressed by us as early as possible and always within one month.
You may also contact your local data protection authority within the European Economic Area or the UK or a relevant national authority for unresolved complaints.
</p>

<h2>
5. PURPOSES FOR WHICH WE WILL SHARE YOUR PERSONAL DATA
</h2>
<p>
We may share the above categories of Personal Data for certain business purposes, including:
Storage
Personal Data of our users are stored in our data centers of our third party service providers in Canada and Germany, who act as data processors
Sharing with our service providers and partners
We use certain service providers and partners to help us operate and improve our Services. These service providers and partners assist us with various tasks, including hosting and maintenance, on-going development, analytics, customer care and marketing.
For California residents, we may also “sell” or “share” your Personal Information, as defined under the California Consumer Privacy Act, and as amended by the California Privacy Rights Act for the purposes set out below. For more information about your rights as a California resident, please visit the “California Privacy Notice and Rights” section below.
For Virginia residents, we may also share or otherwise “sell” your Personal Data, as defined by the Virginia Consumer Data Protection Act (the “VCDPA”) for the purposes set out below, noting that a “sale” of personal data does not have the literal meaning of the word “sell” and as broadly defined by the VCDPA, includes exchanging of your personal data with third parties for “monetary consideration”. For more information about your rights as a Virginia resident, please visit the “Virginia Privacy Notice and Rights” section below.
Advertising
We may share your information with advertising partners who distribute advertising in our Services. For further information on the advertising networks which may receive this information, go to ‘APPENDIX A - Advertising networks and their partners’ below. If you'd like to withdraw consent, or opt-out from having advertisements personalized in this way, please see the ‘Opt-out of targeted advertising’ section above.
In addition to the above, we may share your Personal Data in the following circumstances or for the following purposes:
For corporate transactions
We may transfer your information if we are involved, whether in whole or in part, in a merger, sale, acquisition, divestiture, restructuring, reorganization, dissolution, bankruptcy or other change of ownership or control.
When required by law
We may also share information if we are under a duty to disclose or share your Personal Data in order to comply with any legal obligation, or to protect the rights, property, or safety of our business, our customers or others.
To enforce legal rights
We may also share information: (i) if disclosure would mitigate our liability in an actual or threatened lawsuit; (ii) as necessary to protect our legal rights and legal rights of our users, business partners or other interested parties; (iii) to enforce our agreements with you; and (iv) to investigate, prevent, or take other action regarding illegal activity, suspected fraud or other wrongdoing.
With your consent or at your request
We may ask for your consent to share your information with third parties. In any such case, we will make it clear why we want to share the information.
Cross-border data transfers
Sharing of information sometimes involves cross-border data transfers, including transfers outside of the EEA in accordance with the law. We only transfer Personal Data to entities in third countries that have provided appropriate safeguards to ensure that their level of data protection is in agreement with this privacy policy and applicable law, under standard contractual provisions which have been deemed by the European Commission to provide sufficient safeguards for Personal Data.
Social sharing features
Some of our Services offer social sharing features which let you share actions you take in our Services with other media. You must be over the minimum age limit that is prescribed by the legislation in the individual jurisdictions to use any social sharing features integrated in our Services. The use of such features enables the sharing of information with your friends or the public, depending on the settings you establish with the entity that provides the social sharing feature. Our Services have social features from the following providers:
<h3>Facebook - https://www.facebook.com/about/privacy/</h3>
<h3>Instagram - https://help.instagram.com/519522125107875?helpref=page_content</h3>
</p>
<h2>
6. AGE LIMITS
</h2>
<p>
We appreciate the need to provide extra privacy protections to users who are children. Our Services are intended for general audiences.
</p>
<h2>
7. LINKS TO OTHER SITES
</h2>
<p>
Our Privacy Policy may contain links to other sites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the privacy terms of every site you visit. We have no control over and assume no responsibility for the content, privacy terms or practices of any third-party sites or services.
</p>
<h2>
8. SUPPLEMENTAL CALIFORNIA PRIVACY NOTICE AND RIGHTS
</h2>
<p>
This Supplemental California Privacy Notice only applies to our processing of personal information that is subject to the California Consumer Privacy Act of 2018 (“CCPA”). The California Consumer Privacy Act (“CCPA”), as amended and expanded by the California Privacy Rights Act (“CPRA”) provides California residents with the additional rights listed here. To exercise these rights, see the “Exercising Your California Privacy Rights” subsection below.
Right to know what personal information is collected (Right of Access)
You have the right to know and see what information we have collected about you over the past 12 months, including:
The categories of information we have collected about you;
The categories of sources from which the information is collected;
The business or commercial purpose for collecting your information;
The categories of third parties with whom we have shared your information; and
The specific pieces of information we have collected about you.
For more information about specific pieces of information we collect, please visit the “INFORMATION WE COLLECT AND HOW” section above.
Right to Delete
You have the right to request that we delete the information we have collected from you (and direct our service providers and third parties where your information has been “sold” or “shared” to do the same), unless this proves impossible or involves disproportionate effort. There are a number of exceptions, however, that include, but are not limited to, when the information is necessary for us or a third party to do any of the following:
Complete your transaction;
Provide you a good or service;
<h3>Perform a contract between us and you;</h3>
Protect your security and prosecute those responsible for breaching it;
<h3>Fix our system in the case of a bug;</h3>
<h3>Protect the free speech rights of you or other users;</h3>
Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et seq.);
Engage in public or peer-reviewed scientific, historical, or statistical research in the public interests that adheres to all other applicable ethics and privacy laws;
Comply with a legal obligation; or
Make other internal and lawful uses of the information that are compatible with the context in which you provided it.
<h3>Right to Opt-out</h3>
You have the right to “opt-out,” or direct us to stop “selling” or otherwise sharing your information with third parties in exchange for monetary or other valuable consideration. To submit an opt-out request, please follow the instructions under the ‘Opt-out of targeted advertising’ paragraph found above, to configure the Privacy Setting in any of our apps or by checking your device privacy settings.
<h3>Other Rights</h3>
You can request certain information about our disclosure of your information to third parties for their own direct marketing purposes during the preceding calendar year (to the extent we have shared information for such purposes within the given period). This request is free and may be made once a year. In certain cases, you may also have the right to correct inaccurate personal information we hold about you. You also have the right not to be discriminated against for exercising any of the rights listed above.
Exercising Your California Privacy Rights
To request access to or deletion of your personal information, to opt-out, or to exercise any other rights under California law, please follow the instruction under the ‘Exercise your privacy rights’ paragraph, found above, and follow the necessary steps.
<h3>Response Timing and Format</h3>
We aim to respond to a consumer request for access or deletion within 45 days of receiving that request. If we require more time, we will inform you of the reason and extension period in writing. If we cannot satisfy your request, we will respond to you with our justification for declining to take action. If you wish to appeal this decision, you can contact us through the method provided in “Contact Us” below.
</p>
<h2>
9. SUPPLEMENTAL VIRGINIA PRIVACY NOTICE AND RIGHTS
</h2>

<h2>
10. CHANGES TO THIS PRIVACY POLICY
</h2>
<p>
This Privacy Policy may change from time to time, and we encourage you to review it periodically. Your continued use of our Services signifies your acceptance of changes to this Privacy Policy.
In some cases, we may notify you of changes to this Privacy Policy with a prominent notice on our Services (for example by way of notification or pop-up), before the changes become effective and update the "effective date" at the top of this Privacy Policy.
</p>
<h2>
11. CONTACT US
</h2>
<p>
If you have any questions about this Privacy Policy, please contact us using the in-app support feature in our Services, which is the preferred method to be able to quickly respond your requests or contact our data protection officer at the address provided below:<br />
planet.do.team@gmail.com
<h3>APPENDIX A - Advertising networks and their partners</h3>
(Last Updated: February 2023 with effective date 22 Febryary 2023) For personalized advertising, the advertising networks and their partners, that are listed below, and would like your consent, if you reside in EEA, the UK, Norway, Switzerland or Brazil, unless they have another legal basis for processing, may collect or receive device information from our apps and other apps that include personal and non-personal information, such as advertising (or ad) identifiers, IP address, or other information regarding the delivery of advertisements and your interaction with them, inventory of installed apps and/or other tracking technologies to provide measurement services, personalization, ad selection, content selection, delivery, reporting and optimize targeted ads. Ad identifiers are non-permanent device identifiers such as the Android advertising ID and/or Apple's ID for advertising (IDFA). For more information on how to opt-out go to Privacy Policy Opt-out of targeted advertising further above. In addition, the advertising networks listed below, with the exception of Fyber, Index Exchange and PubNative who act as data processors upon our instructions, are independent data controllers in respect of your Personal Data and you can learn more about how each handles your data by accessing their respective privacy notices using the hyperlinks below.<br />
<h3>Facebook Audience Network</h3>
Facebook Audience Network is an advertising service provided by Facebook, Inc. (US) that may collect or receive information from our apps and other apps and use that information to provide measurement services and targeted ads. Facebook does not require opt-in or opt-out signals from users in our Services, relevant signals are collected directly from the user upon creating an account in such service, except for users who are based in California and exercise their right to opt-out. Privacy Policy: https://www.facebook.com/about/privacy/ You can manage your personalized ad preferences in relation to Facebook directly from your Facebook account.<br />
<h3>Ad Manager by Google</h3>
Ad Manager by Google is provided by Google Ireland Ltd (Ireland) For more information on how Ad Manager uses the collected information, please visit the Privacy Policy of Google: https://policies.google.com/privacy You can opt-out from Ad Manager by Google service by following the instructions described by Google: https://support.google.com/ads/answer/2662922?hl=en<br />
<h3>Google AdMob and their partners</h3>
AdMob by Google is provided by Google Inc. (US) For more information on how AdMob uses the collected information, please visit the Privacy Policy of Google: https://www.google.com/policies/privacy/ For more information on AdMob Partners, please visit: https://support.google.com/admob/answer/9012903 You can opt-out from AdMob by Google service by following the instructions described by Google: https://support.google.com/ads/answer/2662922?hl=en<br />
<h3>Google AdSense</h3>
Google, as a third-party vendor, uses cookies to serve ads on our Service. Google's use of the DoubleClick cookie enables it and its partners to serve ads to our users based on their visit to our Service or other websites on the Internet. You may opt-out of the use of the DoubleClick Cookie for interest-based advertising by visiting the Google Ads Settings web page: https://www.google.com/ads/preferences/<br />
<h3>Unity Ads</h3>
Unity Ads is an advertising service provided by Unity Ads Inc. (US) Privacy Policy: https://unity3d.com/legal/privacy-policy<br />
<h3>Facebook Ads conversion</h3>
Facebook Ads conversion tracking is an analytics service provided by Facebook, Inc. (US) that connects data from the Facebook advertising network with actions performed on the Services. Privacy Policy: https://www.facebook.com/about/privacy/<br />
<h3>Facebook Analytics</h3>
Facebook Analytics is an analytics tool provided by Facebook, Inc. (US) that may collect or receive information from our apps and other apps and use that information to provide measurement services. Privacy Policy: https://www.facebook.com/about/privacy/<br />
<h3>Firebase</h3>
Firebase is an analytics service provided by Google Inc. (US) You may opt-out of certain Firebase features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in their Privacy Policy: https://www.google.com/intl/en/policies/privacy/<br />
<h3>Google Analytics </h3>
Google Analytics is an analytics service provided by Google Inc. Privacy Policy: https://policies.google.com/privacy For more information visit the site “How Google uses data when you use our partners' sites or apps” available at https://policies.google.com/technologies/partner-sites<br />
<h3>Google reCAPTCHA </h3>
Google reCAPTCHA is a spam protection service provided by Google LLC. (US) It is primarily used to distinguish whether an input is made by a natural person or abusive by automated processing. The service includes sending data required by Google for the reCAPTCHA service to Google. Additional information about Google reCAPTCHA and Google's privacy policy can be found at https://policies.google.com/privacy.
</p>
</div>
        
    );
}